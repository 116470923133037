import { theme } from '../styles/theme';

const { contacts } = theme;

export const sliderAlt1 = 'wydruki-wielkoformatowe';

export const mainHeader = 'Drukarnia wielkoformatowa';

export const paragraphs = [
  {
    text: `Przekazy marketingowe w błyskawicznym tempie potrafią dotrzeć do świadomości odbiorców. Podobnie ma się rzecz
    m.in. z okładkami książek. Jeśli jako przedsiębiorca szukasz sposobu na udaną realizację materiałów pomagających w
    kreowaniu pozytywnego wizerunku firmy, promocji eventów i nie tylko, trafiłeś we właściwe miejsce.`,
  },
  {
    text: `Przygotowanie do druku i tworzenie niewielkich wizytówek po wielkoformatowe banery uchodzi za naszą specjalność.
    Pracownia cyfrowa i offsetowa realizuje również montaż reklam. Umożliwiamy też wykonanie projektów okładek książek
    lub broszur, w czym pomaga nasza introligatornia.`,
  },
  {
    text: '**Poznaj wszystkie metody działania**',
  },
  {
    text: `Materiały reklamowe mogą znaleźć się na witrynach sklepowych, elewacjach budynków, ogrodzeniach czy też
    samochodach osobowych i dostawczych. Tyczy się to również montażu kasetonów. Poszczególne z nich są
    przygotowywane i realizowane stosując cyfrowy, offsetowy, lateksowy lub solwentowy druk wielkoformatowy. Identycznie
    działa nasza introligatornia przy projektach opraw książek i zdobieniu.`,
  },
  {
    text: `W zależności od założeń indywidualnego projektu przygotowujemy dowolne uszlachetnienia reklam przeznaczonych do
    montażu. Również i inne wydruki wielkoformatowe cyfrowe i offsetowe, ale i nie tylko, wychodzące z introligatorni, w tym
    fotoplakaty, roll-up'y, mogą zawierać lakier odporny na promienie UV czy też ozdobną folię w celu zwiększenia walorów
    wizualnych materiałów oraz poprawy ochrony przed oddziaływaniem czynników.`,
  },
  {
    text: '**Skorzystaj z naszej pomocy**',
  },
  {
    text: `Drukarnia cyfrowa i offestowa Banerbunny służy wsparciem na każdym etapie realizacji projektów i usług introligatorskich
    – począwszy od przygotowania materiałów kończąc na sposobie montażu reklam wielkoformatowych. Doradzimy również
    w kwestii wysokości nakładów gadżetów firmowych, w tym notesów czy kalendarzy. Tyczy się to również wizytówek i
    plakatów.`,
  },
  {
    text: `Jeśli chcesz dowiedzieć się, jak działa nasza drukarnia offestowa i cyfrowa wraz z introligatornią, zachęcamy do kontaktu
    telefonicznego lub mailowego. Ustalimy wszelkie szczegóły dotyczące przygotowania i wykonania projektów
    wielkoformatowych, a także pory montażu reklam.`,
  },
  {
    text: 'Wybierz kategorię produktów która Cię interesuję i zapoznaj się z ich **specyfikacją**, **zdjęciami** i **ceną** oraz **terminem realizacji**.',
  },
  {
    text: 'Przy większości produktów **cennik** widoczny jest na stronie, lecz przy niektórych materiałach oszacowanie ich ceny jest możliwe po ustaleniu większej ilości szczegółów.',
  },
  {
    text: `Chętnie odpowiemy na wszystkie pytania oraz doradzimy w kwestii doboru materiału, 
    tym samym zachęcamy do kontaktu telefonicznego: [${contacts.phone}](tel:516964323) lub mailowego: [${contacts.email}](mailto:${contacts.email})`,
  },
  {
    text: 'Oferujemy również wykonanie projektów graficznych do wszystkich dostępnych materiałów reklamowych.',
  },
];

export const category = [
  {
    text: 'Druk \n wielkoformatowy',
  },
  {
    text: 'Druk cyfrowy',
  },
  {
    text: 'Druk offsetowy',
  },
  {
    text: 'Montaż',
  },
  {
    text: 'Projektowanie',
  },
  {
    text: 'Introligatornia',
  },
];

export const HaveAQuestion = 'Masz pytania?';
export const contactUs = 'Napisz do nas';

export const hint = `Potrzebujesz materiałów których nie widzisz w ofercie?
Strona jest na etapie aktywnej rozbudowy oraz będzie uzupełniana o kolejne produkty.
Napisz do nas, a być może będziemy mogli Ci pomóc :)`;
