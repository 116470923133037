import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const StyledCategoryList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;

  ${breakpoint('xl')`
    flex-direction: row;
    align-items: flex-end;
  `}
`;

export const StyledCategoryListItem = styled.li`
    width: 100%;
    height: 140px;
    font-size: 1.9rem;
    border-radius: 0;
    border: 0;
    color: white;
    text-shadow: 1px 1px 7px rgba(0,0,0,.7);
    position: relative;
    overflow: hidden;
    transition: padding-bottom .2s ease-in-out;
    cursor: pointer;

    ${breakpoint('xl')`
      height: 30vw;
      min-height: 450px;
      max-height: 900px;
      font-size: 1.2vw;
    `}

    &::before {
      content: '';
      width: 15%;
      height: 2px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 100;
      transform: translateX(-100%);
      opacity: 0;
      transition: all .2s linear;

      ${breakpoint('xl')`
        display: none;
      `}
    }

    &:hover {
      &::before {
        transform: translateX(0);
        opacity: 1;
        ${breakpoint('xl')`
          transform: translateY(0);
        `}
      }
      
      ${breakpoint('xl')`
        padding-bottom: 50px;
      `}
    }

    &:nth-of-type(1) {
      background-color: #f3722c;
    }
    &:nth-of-type(2) {
      background-color: #f8961e;
    }
    &:nth-of-type(3) {
      background-color: #f9c74f;
    }
    &:nth-of-type(4) {
      background-color: #90be6d;
    }
    &:nth-of-type(5) {
      background-color: #43aa8b;
    }
    &:nth-of-type(6) {
      background-color: #184e77;
    }

    a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-weight: 700;
    }
  `;

export const StyleContactWrapper = styled.div`
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 30vw;
  margin: 0 auto;
  gap: 25px;
  border-radius: 50%;
  box-shadow: 0 4px 84px 0 rgb(154 167 180 / 20%);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  min-height: 500px;
  min-width: 500px;

  @media screen and (min-width: 360px) {
    padding: 40px 23vw;
  }

  ${breakpoint('sm')`
    width: 50vw;
    height: 50vw;
    max-width: 700px;
    max-height: 700px;
    padding: 40px;
    left: initial;
    transform: none;
  `}

  ${breakpoint('xl')`
    padding: 80px;
  `} 

  p {
    text-align: center;
  }
`;
