/* eslint-disable react/prop-types */

/* Plugins */
import React from 'react';
import { graphql, navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Markdown from 'react-markdown';

/* Global variables */
import { theme } from '../styles/theme';

/* Utils */
import { handleCleanPath } from '../utils/utils';

/* Components */
import {
  Container, Heading, Button, Article, Wrapper, SEO, ProductCard,
} from '../components/components';

/* Styles */
import {
  StyledCategoryList,
  StyledCategoryListItem,
  StyleContactWrapper,
} from '../styles/homePage.styles';
import { ProductCardsWrapper } from '../components/Product/ProductCard/ProductCard.styled';

/* Messages */
import * as messages from '../messages/home.messages';

const IndexPage = ({ data }) => {
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    autoPlay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const sendEmailHandler = () => {
    window.location.href = `mailto:${theme.contacts.email}`;
  };

  const anchorLinkList = [
    '/drukarnia',
    '/drukarnia#druk-cyfrowy-i-offsetowy',
    '/drukarnia#druk-cyfrowy-i-offsetowy',
    '/montaz',
    '/projekty-graficzne',
    '/drukarnia#introligatornia-i-uszlachetnienia',
  ];

  return (
    <>
      <SEO />
      <main>
        <Slider {...settings}>
          <GatsbyImage
            image={data.image1.childImageSharp.gatsbyImageData}
            alt={messages.sliderAlt1}
          />
        </Slider>

        <Wrapper>
          <Container>
            <Article>
              <Heading headingLevel="h1" title={messages.mainHeader} centered />
              {messages.paragraphs.map(({ text }) => <Markdown children={text} key={text} />)}
            </Article>
          </Container>
        </Wrapper>

        <Wrapper backgroundColor="#F3F4F5">
          <Container>
            <ProductCardsWrapper>
              {data.categories.edges.map(({ node }) => (
                <ProductCard
                  key={node.name}
                  heading={node.name}
                  alt={node.name}
                  image={node.childImageSharp.gatsbyImageData}
                  onClick={() => navigate(handleCleanPath(`/${node.name}/`))}
                />
              ))}
            </ProductCardsWrapper>
          </Container>
        </Wrapper>

        <Wrapper noPadding>
          <StyledCategoryList>
            {messages.category.map(({ text }, index) => (
              <StyledCategoryListItem
                key={text}
                title={text}
              >
                <AnchorLink
                  to={anchorLinkList[index]}
                >
                  {text}
                </AnchorLink>
              </StyledCategoryListItem>

            ))}
          </StyledCategoryList>
        </Wrapper>

        <Wrapper backgroundColor="#F3F4F5">
          <Container>
            <StyleContactWrapper>
              <Heading headingLevel="h2" title={messages.HaveAQuestion} centered />
              <Markdown children={messages.hint} />
              <Button title={messages.contactUs} onClick={sendEmailHandler} primary />
            </StyleContactWrapper>
          </Container>
        </Wrapper>
      </main>
    </>
  );
};

export const pageQuery = graphql`
  query {
    image1: file(name: { eq: "slider-image1" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    categories: allFile(filter: {relativeDirectory: {eq: "categories"}}) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default IndexPage;
